<template>
  <div class="sctb">
    <v-row>
      <v-col cols="12">
        <!-- logo -->
        <div class="logo d-flex justify-center">
          <router-link :to="{ name: 'Home' }" class="d-block lh-0">
            <img :src="addResourceImage($store.getters['app/appLogo'])" :alt="$store.getters['app/appName']" height="60" @error="imageFallback($event)" />
          </router-link>
        </div>

      </v-col>
    </v-row>

    <!-- banner -->
    <template v-if="$store.getters['app/banners'].shop_registration_page.img">
      <banner
        :loading="false"
        :banner="$store.getters['app/banners'].shop_registration_page"
        class="mb-5"
      />
    </template>
  </div>
      
</template>

<script>
export default {

}
</script>

<style>

</style>